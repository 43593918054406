<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-info">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'users-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer un nouvel utilisateur
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">id</th>
          <th scope="col">Statut</th>
          <th scope="col">Pays</th>
          <th scope="col">Ville</th>
          <th scope="col">lat</th>
          <th scope="col">lon</th>
          <th scope="col">IP</th>
          <th scope="col">Créé à</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(authentication, index) in authentications" :key="index++">
          <td class="">
            {{ authentication.id }}
          </td>

          <td class="">
            {{ authentication.status }}
          </td>

          <td class="">
            {{ authentication.country }}
          </td>

          <td class="">
            {{ authentication.city }}
          </td>

          <td class="">
            {{ authentication.lat }}
          </td>

          <td class="">
            {{ authentication.lon }}
          </td>

          <td class="">
            {{ authentication.query }}
          </td>

          <td class="">
            {{ authentication.created_at }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", {
      authentications: "getAllAuthentications",
    }),
  },
  mounted() {
    this.$store.dispatch("auth/fetchAllAuthentications");
  },
  methods: {
    async refresh() {
      return await this.$store.dispatch("auth/fetchAllAuthentications");
    },
  },
};
</script>
